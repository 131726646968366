import { render, staticRenderFns } from "./ReadFile.vue?vue&type=template&id=5c6f69bf&scoped=true&"
import script from "./ReadFile.vue?vue&type=script&lang=js&"
export * from "./ReadFile.vue?vue&type=script&lang=js&"
import style0 from "./ReadFile.vue?vue&type=style&index=0&id=5c6f69bf&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c6f69bf",
  null
  
)

export default component.exports