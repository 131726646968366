<template>
  <div>
    <v-toolbar color="orange darken-3">
      <v-toolbar-title> {{ view_type }} File </v-toolbar-title>

      <v-spacer />

      <v-btn icon @click="$emit('close-file-dialog')" title="Click to close">
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </v-toolbar>

    <div class="main-content px-10">
      <div v-if="loadingPage" class="mt-10 d-flex justify-center align-center">
        <v-progress-circular class="mr-2" indeterminate color="teal" />
        Please wait while we load the files...
      </div>

      <div v-if="!loadingPage">
        <div v-if="error" class="mt-2">
          <v-alert
            v-for="(err, index) in errors"
            :key="index"
            type="error"
            dismissible
            outlined
          >
            {{ err.message }}
          </v-alert>
        </div>

        <div class="split-container" v-if="isSplit">
          <!-- Content for Column 1 -->
          <div class="column1 px-2" ref="column1">
            <v-row class="mt-5">
              <v-col cols="12" lg="4">
                <v-select
                  v-model="selectedCategory"
                  :items="
                    categories.filter((c) =>
                      files.some((f) => f.file_cat.id === c.id)
                    )
                  "
                  item-text="name"
                  item-value="id"
                  label="Filter by Category"
                  multiple
                  clearable
                  solo
                />
              </v-col>

              <v-col cols="12" lg="4">
                <v-select
                  v-model="selectedFileType"
                  :items="
                    file_types.filter((ft) =>
                      files.some((f) => f.file_type.id === ft.id)
                    )
                  "
                  item-text="name"
                  item-value="id"
                  label="Filter by File Type"
                  multiple
                  clearable
                  solo
                />
              </v-col>

              <!-- search field -->
              <v-col>
                <v-text-field
                  v-model="search"
                  label="Search"
                  prepend-inner-icon="mdi-magnify"
                  clearable
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" lg="3">
                <v-switch
                  v-model="showArchived"
                  :label="`List of all: ${
                    showArchived ? 'Archived' : 'Active'
                  } files`"
                  @change="
                    selectedFiles = [];
                    selectedCategories = [];
                    selectedAllCategories = false;
                  "
                  dense
                  class="mt-0"
                  color="teal"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" lg="6" class="d-flex align-center flex-wrap">
                <v-checkbox
                  v-model="selectedAllCategories"
                  color="teal"
                  class="mt-0 mr-5"
                  label="Select All Categories"
                  @click="handle_select_category('all')"
                  hide-details
                />

                <v-btn
                  :disabled="selectedFiles.length > 0"
                  small
                  title="Click to Export"
                  @click="handle_export_files"
                >
                  <div v-if="downloadingFiles">
                    <v-progress-circular
                      indeterminate
                      color="white"
                      size="20"
                      class="mr-2"
                    />

                    Please wait...
                  </div>

                  <div v-else>export all files</div>
                </v-btn>
              </v-col>

              <v-col cols="12" lg="6" class="d-flex align-center justify-end">
                <v-btn
                  v-if="selectedFiles.length > 0"
                  color="teal"
                  small
                  title="Click to Download"
                  @click="handleDownloadFiles"
                >
                  <div v-if="downloadingFiles">
                    <v-progress-circular
                      indeterminate
                      color="white"
                      size="20"
                      class="mr-2"
                    />

                    Please wait...
                  </div>

                  <div v-else>download files</div>
                </v-btn>
              </v-col>
            </v-row>

            <p class="text-center grey--text text-body-1">
              {{ filteredCategories.length === 0 ? "No files found" : "" }}
            </p>

            <v-expansion-panels v-model="panels" class="mt-5" multiple>
              <v-expansion-panel
                v-for="category in filteredCategories"
                :key="category.id"
                class="bordered"
              >
                <v-expansion-panel-header>
                  <span class="d-flex align-center">
                    <v-checkbox
                      v-model="selectedCategories"
                      :value="category.id"
                      @click.native.stop="
                        handle_select_category('category', category.id)
                      "
                      color="teal"
                      dense
                    />

                    <p class="mb-2">
                      {{ category.name }}
                    </p>

                    <span class="grey--text">
                      <p class="mb-2">
                        -
                        {{ filteredFiles(category.id).length }}
                        Files
                      </p>
                    </span>
                  </span>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <p
                    v-if="filteredFiles(category.id).length === 0"
                    class="text-subtitle-2 grey--text d-flex justify-center align-center"
                  >
                    No files found. Click upload file to create a new file.
                  </p>

                  <v-list v-if="filteredFiles(category.id).length > 0">
                    <v-list-item
                      v-for="file in filteredFiles(category.id)"
                      :key="file.id"
                      link
                      class="file-item"
                    >
                      <v-list-item-action>
                        <v-checkbox
                          v-model="selectedFiles"
                          :value="file.id"
                          color="teal"
                          hide-details
                        />
                      </v-list-item-action>

                      <v-list-item-content @click.stop="openSplit(file)">
                        <v-list-item-title>
                          <div v-if="editingFileId === file.id">
                            <v-text-field
                              v-model="editingName"
                              @blur="saveNewName(file.id)"
                              @keyup.enter="saveNewName(file.id)"
                              dense
                              autofocus
                              solo-inverted
                              hide-details
                            />
                          </div>

                          <div v-else>
                            <v-row>
                              <v-col cols="12" lg="4" style="overflow: hidden">
                                {{ file.name }}
                              </v-col>

                              <v-col cols="12" lg="4" style="overflow: hidden">
                                {{ file.description }}
                              </v-col>

                              <v-col cols="12" lg="4" style="overflow: hidden">
                                <span
                                  title="Notes"
                                  class="pl-2 ml-2"
                                  style="border-left: solid grey 1px"
                                >
                                  <v-icon size="15">mdi-message-outline</v-icon>
                                  {{ file.notes.length }}
                                </span>

                                <span
                                  title="File Type"
                                  class="pl-2 ml-2"
                                  style="border-left: solid grey 1px"
                                >
                                  <v-icon size="15">
                                    mdi-file-document-outline
                                  </v-icon>
                                  {{ file.file_type.name }}
                                </span>

                                <span
                                  title="Date Uploaded"
                                  class="pl-2 ml-2"
                                  style="border-left: solid grey 1px"
                                >
                                  <v-icon size="15"
                                    >mdi-calendar-outline</v-icon
                                  >
                                  {{ file.createdAt | formatDate }}
                                </span>
                              </v-col>
                            </v-row>
                          </div>
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action class="action-icons" v-show="false">
                        <v-btn
                          icon
                          small
                          @click="startEdit(file)"
                          class="mr-2"
                          title="Rename"
                        >
                          <v-icon>mdi-pencil-outline</v-icon>
                        </v-btn>

                        <v-btn
                          v-show="!showArchived"
                          icon
                          small
                          @click="openArchiveFile(file)"
                          title="Archive"
                          class="mr-2"
                        >
                          <v-icon>mdi-archive-arrow-down-outline</v-icon>
                        </v-btn>

                        <v-btn
                          icon
                          small
                          @click="
                            view_file = true;
                            openSplit(file);
                          "
                          class="mr-2"
                          title="View"
                        >
                          <v-icon>mdi-eye-outline</v-icon>
                        </v-btn>

                        <v-btn
                          icon
                          small
                          @click="downloadFile(file.dataUrl, file.name)"
                          title="Download"
                        >
                          <v-icon>mdi-download</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>

          <div class="resizer" @mousedown="startResizing" />

          <!-- Content for Column 2 -->
          <div class="column2 pl-2" style="max-width: 50%" ref="column2">
            <div class="d-flex justify-space-between align-center my-5">
              <v-btn icon small @click="toggleSplit" title="Hide Details">
                <v-icon>mdi-chevron-double-right</v-icon>
              </v-btn>

              <v-btn @click="view_file = !view_file">
                <v-icon class="mr-2">{{
                  view_file ? "mdi-eye" : "mdi-eye-off"
                }}</v-icon>
                preview
              </v-btn>
            </div>

            <v-card v-if="view_file" flat>
              <v-card-text>
                <v-row
                  v-if="!viewableFile(temp_file.type) || !temp_file.dataUrl"
                >
                  <v-col
                    class="d-flex justify-center flex-column align-center py-10"
                  >
                    <v-icon color="grey" large>
                      {{ getFileIcon(temp_file.type) }}
                    </v-icon>
                    {{ temp_file.name }}
                  </v-col>
                </v-row>

                <v-row v-if="viewableFile(temp_file.type) && temp_file.dataUrl">
                  <v-col
                    class="d-flex justify-center flex-column align-center py-5"
                    style="height: 50vh"
                  >
                    <v-img
                      v-if="temp_file.type.includes('image')"
                      :src="temp_file.dataUrl"
                      :alt="temp_file.name"
                      max-width="100%"
                      max-height="100%"
                    />

                    <v-btn
                      v-if="temp_file.type.includes('image')"
                      class="download-button"
                      color="teal darken-2"
                      title="Download"
                      @click="downloadFile(temp_file.dataUrl, temp_file.name)"
                    >
                      <v-icon>mdi-download</v-icon>
                    </v-btn>

                    <embed
                      v-else
                      :src="temp_file.dataUrl"
                      type="application/pdf"
                      width="100%"
                      height="100%"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-divider class="my-5" />

            <p class="text-h6 mb-0">
              File Properties
              <v-btn
                v-if="!editFileProp"
                icon
                small
                title="Click to edit"
                @click="editFileProp = true"
              >
                <v-icon class="text-h6">mdi-pencil-outline</v-icon>
              </v-btn>
            </p>

            <v-form ref="file_prop_form">
              <v-simple-table class="mt-5" dense>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td>Category</td>
                      <td>
                        <div v-if="editFileProp" class="pt-5">
                          <v-select
                            v-model="temp_file.file_cat.id"
                            :items="categories"
                            item-text="name"
                            item-value="id"
                            :rules="[rule.required]"
                            label="Category"
                            clearable
                            dense
                          />
                        </div>
                        <div v-else>
                          {{ temp_file.file_cat.name }}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>File Type</td>
                      <td>
                        <div v-if="editFileProp" class="pt-5">
                          <v-select
                            v-model="temp_file.file_type.id"
                            :items="file_types"
                            item-text="name"
                            item-value="id"
                            :rules="[rule.required]"
                            label="File Type"
                            clearable
                            dense
                          />
                        </div>
                        <div v-else>
                          {{ temp_file.file_type.name }}
                        </div>
                      </td>
                    </tr>

                    <tr class="grey--text">
                      <td>File Type Description</td>

                      <td>
                        {{
                          temp_file.file_type.id
                            ? file_types.find(
                                (ft) => ft.id === temp_file.file_type.id
                              ).description
                            : ""
                        }}
                      </td>
                    </tr>

                    <tr>
                      <td width="200">Name</td>
                      <td>
                        <div v-if="editFileProp" class="pt-5">
                          <v-text-field
                            v-model="temp_file.name"
                            :rules="[rule.required]"
                            label="Name"
                            outlined
                            dense
                          />
                        </div>
                        <div v-else>
                          {{ temp_file.name }}
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>Description</td>
                      <td>
                        <div v-if="editFileProp" class="pt-5">
                          <v-textarea
                            v-model="temp_file.description"
                            label="Description"
                            outlined
                            dense
                          />
                        </div>
                        <div v-else>
                          {{ temp_file.description }}
                        </div>
                      </td>
                    </tr>

                    <tr v-if="!editFileProp">
                      <td>Size</td>
                      <td>
                        {{ temp_file.size | byteToMegabyte }}
                      </td>
                    </tr>

                    <tr v-if="!editFileProp">
                      <td>Type</td>
                      <td>
                        {{ temp_file.type }}
                      </td>
                    </tr>

                    <tr>
                      <td>Signature Requirement</td>
                      <td>
                        <div v-if="editFileProp" class="pt-4">
                          <v-checkbox
                            v-model="temp_file.signature_requirement"
                            dense
                          />
                        </div>
                        <div v-else>
                          {{ temp_file.signature_requirement }}
                        </div>
                      </td>
                    </tr>

                    <tr v-if="temp_file.signature_requirement">
                      <td>Signature Status</td>
                      <td>
                        <div v-if="editFileProp" class="pt-5">
                          <v-select
                            v-model="temp_file.signature_status"
                            :items="signature_status_options"
                            :rules="[rule.required]"
                            label="Signature Status"
                            clearable
                            dense
                          />
                        </div>
                        <div v-else>
                          {{ temp_file.signature_status }}
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>Status</td>
                      <td>
                        <div v-if="editFileProp" class="pt-5">
                          <v-select
                            v-model="temp_file.status"
                            :items="status_options"
                            :rules="[rule.required]"
                            label="Status"
                            clearable
                            dense
                          />
                        </div>
                        <div v-else>
                          {{ temp_file.status }}
                        </div>
                      </td>
                    </tr>

                    <tr v-if="temp_file.status === 'Archived'">
                      <td>Archived Reason</td>
                      <td>
                        <div v-if="editFileProp" class="pt-5">
                          <v-textarea
                            v-model="temp_file.archived_reason"
                            outlined
                            dense
                          />
                        </div>
                        <div v-else>
                          {{ temp_file.archived_reason }}
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>View Company</td>
                      <td>
                        <div v-if="editFileProp" class="pt-4">
                          <v-checkbox v-model="temp_file.view_company" dense />
                        </div>
                        <div v-else>
                          {{ temp_file.view_company }}
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>View Customer</td>
                      <td>
                        <div v-if="editFileProp" class="pt-4">
                          <v-checkbox v-model="temp_file.view_customer" dense />
                        </div>
                        <div v-else>
                          {{ temp_file.view_customer }}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Provided By</td>
                      <td>
                        <div v-if="editFileProp" class="pt-5">
                          <v-select
                            v-model="temp_file.provided_by"
                            :items="provided_by_options"
                            :rules="[rule.required]"
                            label="Provided By"
                            clearable
                            dense
                          />
                        </div>
                        <div v-else>
                          {{ temp_file.provided_by }}
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colspan="2" class="grey--text text-center">
                        Associates
                      </td>
                    </tr>

                    <tr>
                      <td>Customers</td>
                      <td>
                        <div v-if="editFileProp" class="pt-5">
                          <v-autocomplete
                            v-model="temp_file.associate.customers"
                            :items="customerNames"
                            item-text="name"
                            item-value="id"
                            label="Customers"
                            chips
                            deletable-chips
                            small-chips
                            dense
                            multiple
                          />
                        </div>

                        <div v-else>
                          <v-chip
                            v-for="(customer, index) in customerNames.filter(
                              (c) =>
                                temp_file.associate.customers.includes(c.id)
                            )"
                            :key="index"
                            class="mr-2"
                            small
                          >
                            {{ customer.name }}
                          </v-chip>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>Properties</td>
                      <td>
                        <div v-if="editFileProp" class="pt-5">
                          <v-autocomplete
                            v-model="temp_file.associate.properties"
                            :items="propertyAddresses"
                            item-text="address"
                            item-value="id"
                            label="Properties"
                            chips
                            deletable-chips
                            small-chips
                            dense
                            multiple
                          />
                        </div>

                        <div v-else>
                          <v-chip
                            v-for="(
                              property, index
                            ) in propertyAddresses.filter((p) =>
                              temp_file.associate.properties.includes(p.id)
                            )"
                            :key="index"
                            class="mr-2"
                            small
                          >
                            {{ property.address }}
                          </v-chip>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-form>

            <div class="d-flex justify-end my-5">
              <v-btn
                v-if="editFileProp"
                :disabled="savingFileProperty"
                color="teal"
                title="Click to save"
                @click="saveFileProperty"
                small
              >
                <v-progress-circular
                  v-if="savingFileProperty"
                  indeterminate
                  color="white"
                  size="20"
                  class="mr-2"
                />
                save
              </v-btn>

              <v-btn
                v-if="editFileProp"
                class="ml-2"
                @click="cancelEditFileProperty"
                small
              >
                cancel
              </v-btn>
            </div>

            <v-divider />

            <!-- note list -->
            <v-card style="background: transparent" ref="note_list" flat>
              <p class="mb-0 mt-5 grey--text font-weight-medium text-h6">
                Notes
              </p>

              <v-card-text>
                <p
                  v-if="temp_file.notes.length === 0"
                  class="text-center grey--text text-subtitle-1"
                >
                  No notes available. Make a note below.
                </p>

                <div
                  v-for="(note, index) in temp_file.notes"
                  :key="note.id"
                  class="my-2"
                >
                  <v-card class="rounded-lg">
                    <v-card-text>
                      <p class="d-flex align-center mb-0">
                        <span>
                          <v-icon class="text-body-1 mr-1">
                            mdi-account-outline
                          </v-icon>
                          {{ note.user ? note.user.first_name : "" }}
                          {{ note.user ? note.user.last_name : "" }}
                        </span>

                        <span class="ml-auto">
                          <v-icon class="text-body-1 mr-1">
                            mdi-calendar-outline
                          </v-icon>
                          {{ note.createdAt | formatDate }}
                        </span>

                        <span class="ml-5">
                          <v-btn
                            title="Click to edit note"
                            @click="handle_edit_deal_note(note, index, $event)"
                            icon
                            small
                          >
                            <v-icon color="teal" class="text-body-1">
                              mdi-pencil-outline
                            </v-icon>
                          </v-btn>

                          <v-btn
                            title="Click to delete"
                            @click="handle_delete_file_note(note)"
                            icon
                            small
                          >
                            <v-icon color="red"> mdi-close </v-icon>
                          </v-btn>
                        </span>
                      </p>
                    </v-card-text>

                    <v-divider />

                    <v-card-text>
                      <div v-if="note.note" style="white-space: pre">
                        {{ note.note }}
                      </div>

                      <div
                        v-if="note.content"
                        class="note-contents"
                        v-html="note.content"
                      ></div>
                    </v-card-text>
                  </v-card>
                </div>
              </v-card-text>
            </v-card>

            <!-- note editor -->
            <v-card style="background: transparent" ref="note_editor" flat>
              <v-card-text class="pa-1">
                <div>
                  <RichTextEditor
                    ref="tipTapEditor"
                    :id="temp_file.id"
                    type="read_deal_file_note"
                    :user_id="$store.getters['Auth/getAuthUser'].id"
                    @handleSaveNote="handle_save_deal_note"
                  />
                </div>
              </v-card-text>
            </v-card>

            <v-dialog v-model="note_dialog" fullscreen>
              <v-toolbar class="sticky-toolbar orange darken-3" flat>
                <v-toolbar-title>
                  <v-icon>mdi-image</v-icon> Image Preview
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="note_dialog = false" title="Close">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>

              <v-card>
                <v-card-text>
                  <v-img :src="selectedImage" aspect-ratio="1" />
                </v-card-text>
              </v-card>
            </v-dialog>
          </div>
        </div>

        <div v-else>
          <!-- Content for single column layout -->
          <v-row>
            <v-col>
              <v-row class="mt-5">
                <v-col cols="12" lg="4">
                  <v-select
                    v-model="selectedCategory"
                    :items="
                      categories.filter((c) =>
                        files.some((f) => f.file_cat.id === c.id)
                      )
                    "
                    item-text="name"
                    item-value="id"
                    label="Filter by Category"
                    multiple
                    clearable
                    solo
                  />
                </v-col>

                <v-col cols="12" lg="4">
                  <v-select
                    v-model="selectedFileType"
                    :items="
                      file_types.filter((ft) =>
                        files.some((f) => f.file_type.id === ft.id)
                      )
                    "
                    item-text="name"
                    item-value="id"
                    label="Filter by File Type"
                    multiple
                    clearable
                    solo
                  />
                </v-col>

                <!-- search field -->
                <v-col>
                  <v-text-field
                    v-model="search"
                    label="Search"
                    prepend-inner-icon="mdi-magnify"
                    clearable
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="3">
                  <v-switch
                    v-model="showArchived"
                    :label="`List of all ${
                      showArchived ? 'Archived' : 'Active'
                    } files`"
                    @change="
                      selectedFiles = [];
                      selectedCategories = [];
                      selectedAllCategories = false;
                    "
                    hide-details
                    class="mt-0"
                    color="teal"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="6" class="d-flex align-center flex-wrap">
                  <v-checkbox
                    v-model="selectedAllCategories"
                    color="teal"
                    class="mt-0 mr-5"
                    label="Select All Categories"
                    @click="handle_select_category('all')"
                    hide-details
                  />

                  <v-btn
                    :disabled="selectedFiles.length > 0"
                    small
                    title="Click to Export"
                    @click="handle_export_files"
                  >
                    <div v-if="downloadingFiles">
                      <v-progress-circular
                        indeterminate
                        color="white"
                        size="20"
                        class="mr-2"
                      />

                      Please wait...
                    </div>

                    <div v-else>export all files</div>
                  </v-btn>
                </v-col>

                <v-col cols="12" lg="6" class="d-flex align-center justify-end">
                  <v-btn
                    v-if="selectedFiles.length > 0"
                    color="teal"
                    small
                    title="Click to Download"
                    @click="handleDownloadFiles"
                  >
                    <div v-if="downloadingFiles">
                      <v-progress-circular
                        indeterminate
                        color="white"
                        size="20"
                        class="mr-2"
                      />

                      Please wait...
                    </div>

                    <div v-else>download files</div>
                  </v-btn>
                </v-col>
              </v-row>

              <p class="text-center grey--text text-body-1">
                {{ filteredCategories.length === 0 ? "No files found" : "" }}
              </p>

              <v-expansion-panels v-model="panels" multiple>
                <v-expansion-panel
                  v-for="category in filteredCategories"
                  :key="category.id"
                  class="bordered"
                >
                  <v-expansion-panel-header>
                    <span class="d-flex align-center">
                      <v-checkbox
                        v-model="selectedCategories"
                        color="teal"
                        :value="category.id"
                        @click.native.stop="
                          handle_select_category('category', category.id)
                        "
                        dense
                      />

                      <p class="mb-2">
                        {{ category.name }}
                      </p>

                      <span class="grey--text">
                        <p class="mb-2">
                          -
                          {{ filteredFiles(category.id).length }}
                          Files
                        </p>
                      </span>
                    </span>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <p
                      v-if="filteredFiles(category.id).length === 0"
                      class="text-subtitle-2 grey--text d-flex justify-center align-center"
                    >
                      No files found. Click upload file to create a new file.
                    </p>

                    <v-list v-if="filteredFiles(category.id).length > 0">
                      <v-list-item
                        v-for="file in filteredFiles(category.id)"
                        :key="file.id"
                        link
                        class="file-item"
                      >
                        <v-list-item-action>
                          <v-checkbox
                            v-model="selectedFiles"
                            :value="file.id"
                            color="teal"
                            hide-details
                          />
                        </v-list-item-action>

                        <v-list-item-content @click.stop="openSplit(file)">
                          <v-list-item-title>
                            <div v-if="editingFileId === file.id">
                              <v-text-field
                                v-model="editingName"
                                @blur="saveNewName(file.id)"
                                @keyup.enter="saveNewName(file.id)"
                                dense
                                autofocus
                                solo-inverted
                                hide-details
                              />
                            </div>

                            <div v-else>
                              <v-row>
                                <v-col
                                  cols="12"
                                  lg="4"
                                  style="overflow: hidden"
                                >
                                  {{ file.name }}
                                </v-col>

                                <v-col
                                  cols="12"
                                  lg="4"
                                  style="overflow: hidden"
                                >
                                  {{ file.description }}
                                </v-col>

                                <v-col
                                  cols="12"
                                  lg="4"
                                  style="overflow: hidden"
                                >
                                  <span
                                    title="Notes"
                                    class="pl-2 ml-2"
                                    style="border-left: solid grey 1px"
                                  >
                                    <v-icon size="15"
                                      >mdi-message-outline</v-icon
                                    >
                                    {{ file.notes.length }}
                                  </span>

                                  <span
                                    title="File Type"
                                    class="pl-2 ml-2"
                                    style="border-left: solid grey 1px"
                                  >
                                    <v-icon size="15"
                                      >mdi-file-document-outline</v-icon
                                    >
                                    {{ file.file_type.name }}
                                  </span>

                                  <span
                                    title="Date Uploaded"
                                    class="pl-2 ml-2"
                                    style="border-left: solid grey 1px"
                                  >
                                    <v-icon size="15"
                                      >mdi-calendar-outline</v-icon
                                    >
                                    {{ file.createdAt | formatDate }}
                                  </span>
                                </v-col>
                              </v-row>
                            </div>
                          </v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action class="action-icons" v-show="false">
                          <v-btn
                            icon
                            small
                            @click="startEdit(file)"
                            class="mr-2"
                            title="Rename"
                          >
                            <v-icon>mdi-pencil-outline</v-icon>
                          </v-btn>

                          <v-btn
                            v-show="!showArchived"
                            icon
                            small
                            @click="openArchiveFile(file)"
                            title="Archive"
                            class="mr-2"
                          >
                            <v-icon>mdi-archive-arrow-down-outline</v-icon>
                          </v-btn>

                          <v-btn
                            icon
                            small
                            @click="
                              view_file = true;
                              openSplit(file);
                            "
                            class="mr-2"
                            title="View"
                          >
                            <v-icon>mdi-eye-outline</v-icon>
                          </v-btn>

                          <v-btn
                            icon
                            small
                            @click="downloadFile(file.dataUrl, file.name)"
                            title="Download"
                          >
                            <v-icon>mdi-download</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn @click="$emit('open-upload-file-dialog')">
                upload files
              </v-btn>
            </v-col>
          </v-row>

          <v-dialog v-model="openArchiveFileDialog" max-width="500">
            <v-card v-if="openArchiveFileDialog">
              <v-toolbar class="orange darken-3" flat>
                <v-toolbar-title>Archive File</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  @click="
                    openArchiveFileDialog = false;
                    temp_file.archived_reason = '';
                  "
                  title="Click to close"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>

              <v-card-text class="mt-5 d-flex align-start">
                <v-icon>mdi-information-outline</v-icon>
                <p class="text-body-1 mb-0 ml-4">
                  Are you sure you want to archive this file? This action cannot
                  be undone.
                </p>
              </v-card-text>

              <v-card-text>
                <v-form ref="archiveReason">
                  <v-textarea
                    v-model="temp_file.archived_reason"
                    label="Reason for archiving"
                    :rules="[rule.required]"
                    outlined
                    dense
                  />
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="saveFileProperty('archive')"
                >
                  Submit
                </v-btn>

                <v-btn
                  color="red darken-1"
                  text
                  @click="
                    openArchiveFileDialog = false;
                    temp_file.archived_reason = '';
                  "
                >
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import filt from "@/plugins/filters";
import API from "@/plugins/API";
import { required } from "@/plugins/rule";
import RichTextEditor from "@/components/note/TipTapEditor.vue";

export default {
  props: {
    deal_id: {
      type: Number,
      default: null,
    },

    view_type: {
      type: String,
      default: "Read",
    },

    customer_last_name: {
      type: String,
    },

    deal_public_name: {
      type: String,
    },
  },

  components: {
    RichTextEditor,
  },

  data: () => ({
    loadingPage: false,
    downloadingFiles: false,
    error: false,
    errors: [],
    progress: 0,

    panels: [],

    search: "",
    selectedFileType: [],
    selectedCategory: [],
    showArchived: false,
    selectedAllCategories: false,

    selectedFiles: [],
    selectedCategories: [],

    savingFileProperty: false,

    view_file: false,
    openArchiveFileDialog: false,

    rule: {
      required: required,
    },

    isSplit: false,
    isResizing: false,

    files: [],
    file_types: [],
    categories: [],
    customers: [],
    properties: [],

    editingFileId: null,
    editingName: "",
    editFileProp: false,

    note_dialog: false,
    selectedImage: "",
    temp_note_index: null,

    temp_file: null,

    signature_status_options: ["Signed", "Unsigned", "Signed but incomplete"],

    status_options: ["Active", "Archived", "Draft"],

    provided_by_options: ["Internal", "Lender Provided", "Customer Provided"],
  }),

  methods: {
    openSplit(file_data) {
      this.isSplit = true;

      const new_file_data = { ...file_data };

      this.temp_file = new_file_data;

      this.$nextTick(() => {
        this.$refs.note_list.$el.addEventListener(
          "click",
          this.onDealNoteImageClick
        );
      });
    },

    toggleSplit() {
      this.isSplit = !this.isSplit;
      this.temp_file = null;

      this.$refs.note_list.$el.removeEventListener(
        "click",
        this.onDealNoteImageClick
      );
    },

    startResizing(e) {
      this.isResizing = true;
      document.addEventListener("mousemove", this.resizeColumns);
      document.addEventListener("mouseup", this.stopResizing);
    },

    resizeColumns(e) {
      if (!this.isResizing) return;
      const containerWidth = this.$refs.column1.parentElement.offsetWidth;
      const offsetX = e.clientX;
      const newWidth = (offsetX / containerWidth) * 100;
      this.$refs.column1.style.width = `${newWidth}%`;
      this.$refs.column2.style.width = `${100 - newWidth}%`;
    },

    stopResizing() {
      this.isResizing = false;
      document.removeEventListener("mousemove", this.resizeColumns);
      document.removeEventListener("mouseup", this.stopResizing);
    },

    startEdit(file) {
      this.editingFileId = file.id;
      this.editingName = file.name;
    },

    async saveNewName(id) {
      try {
        const res = await API().patch(
          "api/internal-admin/deal/edit-deal-file-name",
          {
            file_id: id,
            new_name: this.editingName,
          }
        );

        console.log("status: ", res.status);
        console.log("message: ", res.data.message);

        this.files.find((file) => file.id === id).name = this.editingName;

        this.editingName = "";

        this.isSplit = false;
        this.temp_file = null;

        this.editingFileId = null;
      } catch (error) {
        console.error(error);
        this.error = true;
        this.errors.push({ message: error.response.data.message });
      }
    },

    getFileIcon(type) {
      if (type && type.includes("image")) {
        return "mdi-file-image-outline";
      } else {
        return "mdi-file-document-outline";
      }
    },

    viewableFile(type) {
      // can be pdf, image, video, audio
      if (
        type.includes("pdf") ||
        type.includes("image") ||
        type.includes("video") ||
        type.includes("audio")
      ) {
        return true;
      } else {
        return false;
      }
    },

    downloadFile(url, name) {
      const a = document.createElement("a");
      a.href = url;
      a.download = name;
      a.click();
    },

    cancelEditFileProperty() {
      this.editFileProp = false;

      const get_file = this.files.find((file) => file.id === this.temp_file.id);

      this.temp_file = { ...get_file };
    },

    handle_save_deal_note(note, it_is_edit) {
      if (it_is_edit) {
        this.temp_file.notes[this.temp_note_index].content = note.content;
        this.temp_note_index = null;

        const file_index = this.files.findIndex(
          (file) => file.id === this.temp_file.id
        );

        this.files.splice(file_index, 1, this.temp_file);
      } else {
        this.temp_file.notes.push(note);

        const file_index = this.files.findIndex(
          (file) => file.id === this.temp_file.id
        );

        this.files[file_index].notes = [...this.temp_file.notes];
      }
    },

    async handle_delete_file_note(value) {
      try {
        if (window.confirm("Are you sure you want to delete this note?")) {
          const response = await API().post(
            `/api/internal-admin/note/delete?note_id=${value.id}`
          );

          console.log("Response: ", response.message);

          this.temp_file.notes.splice(
            this.temp_file.notes.findIndex((el) => el.id === value.id),
            1
          );
        } else {
          console.log("Delete cancelled");
          return;
        }
      } catch (error) {
        console.log(error);
        this.error = true;
        this.errors.push({ message: error.response.data.message });
      }
    },

    handle_edit_deal_note(note, index, event) {
      event.preventDefault();

      this.temp_note_index = index;

      // set the editor content
      this.$refs.tipTapEditor.setContentToEdit(note);
    },

    onDealNoteImageClick(event) {
      if (event.target.tagName === "IMG") {
        this.selectedImage = event.target.src;
        this.note_dialog = true;
      }
    },

    openArchiveFile(file) {
      if (this.isSplit) this.toggleSplit();
      this.temp_file = file;
      this.openArchiveFileDialog = true;
    },

    filteredFiles(category_id) {
      return this.files.filter((file) => {
        if (file.file_cat.id === category_id) {
          if (this.showArchived) {
            return file.status === "Archived";
          } else {
            return file.status === "Active";
          }
        }
      });
    },

    handle_select_category(type, category_id) {
      switch (type) {
        case "all": {
          if (
            this.selectedCategories.length === this.filteredCategories.length
          ) {
            this.selectedCategories = [];

            this.selectedFiles = [];
          } else {
            this.selectedCategories = this.filteredCategories.map(
              (category) => category.id
            );

            this.selectedFiles = this.files
              .filter(
                (file) =>
                  this.selectedCategories.includes(file.file_cat.id) &&
                  (this.showArchived
                    ? file.status === "Archived"
                    : file.status === "Active")
              )
              .map((file) => file.id);
          }
          break;
        }

        case "category": {
          const files = this.files.filter(
            (file) =>
              file.file_cat.id === category_id &&
              (this.showArchived
                ? file.status === "Archived"
                : file.status === "Active")
          );

          if (this.selectedCategories.includes(category_id)) {
            this.selectedFiles.push(...files.map((file) => file.id));
          } else {
            this.selectedFiles = this.selectedFiles.filter(
              (file_id) => !files.map((file) => file.id).includes(file_id)
            );
          }

          this.selectedAllCategories =
            this.selectedCategories.length === this.filteredCategories.length;
          break;
        }

        default:
          break;
      }
    },

    async handle_export_files() {
      this.selectedFiles = this.files.map((file) => file.id);
      await this.handleDownloadFiles();
      this.selectedFiles = [];
    },

    async handleDownloadFiles() {
      this.downloadingFiles = true;

      // Filter the selected files
      const files = this.files.filter((file) =>
        this.selectedFiles.includes(file.id)
      );

      let formData = new FormData();

      // Helper function to convert blob URL to File
      const fetchBlobToFile = async (url, fileName) => {
        const response = await fetch(url);
        const blob = await response.blob();
        return new File([blob], fileName, { type: blob.type });
      };

      formData.append("customer_last_name", this.customer_last_name);
      formData.append("deal_public_name", this.deal_public_name);

      // Append files and metadata to FormData
      for (const file of files) {
        if (file.dataUrl && file.dataUrl.startsWith("blob:")) {
          try {
            const fileObj = await fetchBlobToFile(file.dataUrl, file.name);
            formData.append("files", fileObj, file.name); // Append file
            formData.append(
              `metadata_${file.name}`,
              JSON.stringify({
                id: file.id,
                file_cat: file.file_cat,
                status: file.status,
                dataUrl: file.dataUrl,
              })
            ); // Append metadata as a JSON string
          } catch (e) {
            console.error("Failed to fetch blob URL:", e);
          }
        } else {
          console.error("Invalid dataUrl format:", file.dataUrl);
        }
      }

      try {
        const response = await API().post(
          "api/internal-admin/deal/download-files", // Ensure this matches your backend route
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            responseType: "blob",
            onUploadProgress: (progressEvent) => {
              this.progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
            },
          }
        );

        // Create a URL for the blob
        const url = window.URL.createObjectURL(new Blob([response.data]));

        // Create a link element
        const link = document.createElement("a");
        link.href = url;
        const date = new Date();
        const fileName = `${this.customer_last_name}-${
          this.deal_public_name
        }-${date.getTime()}.zip`;
        link.setAttribute("download", fileName); // The name of the file

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      } catch (error) {
        console.log("Error downloading files:", error);
      } finally {
        this.downloadingFiles = false;
      }
    },

    async saveFileProperty(type) {
      try {
        this.savingFileProperty = true;
        // validate form
        let valid = null;

        if (type === "archive") {
          valid = await this.$refs.archiveReason.validate();
          valid
            ? (this.temp_file.status = "Archived")
            : (this.temp_file.status = "Active");
        } else {
          valid = await this.$refs.file_prop_form.validate();
        }

        if (!valid) {
          alert("Please fill in all required fields");
          this.savingFileProperty = false;
          return;
        }

        const res = await API().patch(
          "api/internal-admin/deal/edit-deal-file",
          {
            file: this.temp_file,
          }
        );

        console.log("status: ", res.status);
        console.log("message: ", res.data.message);

        this.temp_file = res.data.edited_file;

        const azure_blob = await API().get(
          `/api/internal-admin/get_deal_preview_file?params=${this.temp_file.azure_file_name}`,
          {
            responseType: "blob",
          }
        );

        const file_data = new Blob([azure_blob.data], {
          type: this.temp_file.type,
        });

        const fileURL = URL.createObjectURL(file_data);

        this.temp_file.dataUrl = fileURL;

        const index = this.files.findIndex((f) => f.id === this.temp_file.id);
        if (index !== -1) {
          this.files.splice(index, 1, { ...this.temp_file });
        }

        this.editFileProp = false;
        this.openArchiveFileDialog = false;

        this.savingFileProperty = false;
      } catch (error) {
        console.error(error);
        this.savingFileProperty = false;
        this.error = true;
        this.errors.push({ message: error.response.data.message });
      }
    },

    async getDealFiles() {
      try {
        this.loadingPage = true;

        const get_file_cat_res = await API().get(
          "api/internal-admin/file-cat/get-all-categories"
        );

        this.categories = get_file_cat_res.data.categories;

        const get_file_type_res = await API().get(
          "api/internal-admin/file-type/get-all-file-types"
        );

        this.file_types = get_file_type_res.data.file_types;

        const custs_and_props = await API().get(
          `api/internal-admin/deal/read-customers-and-properties?deal_id=${this.deal_id}`
        );

        this.customers = [...custs_and_props.data.customers];
        this.properties = [...custs_and_props.data.properties];

        const res = await API().get(
          `api/internal-admin/deal/read-deal-files?deal_id=${this.deal_id}`
        );

        this.files = res.data.deal_files;

        this.files.forEach(async (file, index) => {
          this.panels.push(index);
          const azure_blob = await API().get(
            `/api/internal-admin/get_deal_preview_file?params=${file.azure_file_name}`,
            {
              responseType: "blob",
            }
          );

          const file_data = new Blob([azure_blob.data], {
            type: file.type,
          });

          const fileURL = URL.createObjectURL(file_data);

          file.dataUrl = fileURL;
        });

        console.log("status: ", res.status);
        console.log("message: ", res.data.message);

        this.loadingPage = false;
      } catch (error) {
        console.error(error);
        this.loadingPage = false;
        this.error = true;
        this.errors.push({ message: error.response.data.message });
      }
    },
  },

  mounted() {
    this.getDealFiles();
  },

  computed: {
    customerNames() {
      return this.customers.map((customer) => {
        return {
          id: customer.id,
          name: `${customer.first_name} ${customer.last_name}`,
        };
      });
    },

    propertyAddresses() {
      return this.properties.map((p) => {
        return {
          id: p.id,
          address: `${p.address.street_number} ${p.address.street_name}, ${p.address.city}, ${p.address.province} ${p.address.postal_code}`,
        };
      });
    },

    filteredCategories() {
      return this.categories.filter((category) => {
        const searchTerm = this.search ? this.search.toLowerCase() : "";
        const categoryNameMatches = category.name
          ? category.name.toLowerCase().includes(searchTerm)
          : false;
        const categoryMatches = this.selectedCategory.length
          ? this.selectedCategory.includes(category.id)
          : true;
        const fileBelongsToCategory = this.files.some((file) => {
          const matchesFileTypeID = this.selectedFileType.length
            ? this.selectedFileType.includes(file.file_type.id)
            : true;
          const fileNameMatches = file.name
            ? file.name.toLowerCase().includes(searchTerm)
            : false;
          const isActiveOrArchived = this.showArchived
            ? file.status === "Archived"
            : file.status === "Active";
          return (
            (categoryNameMatches || fileNameMatches) &&
            matchesFileTypeID &&
            file.file_cat.id === category.id &&
            isActiveOrArchived
          );
        });

        return categoryMatches && fileBelongsToCategory;
      });
    },
  },

  filters: {
    ...filt,
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  height: 93vh;
  overflow-x: hidden;
  background-color: #111;
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
}

.main-content::-webkit-scrollbar {
  width: 6px;
}

.main-content::-webkit-scrollbar-track {
  background-color: transparent;
}

.main-content::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
}

.main-content::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.split-container {
  display: flex;
  height: 90vh;
}

.column1 {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.column2 {
  flex-grow: 1;
  overflow: auto;
}

.resizer {
  width: 4px;
  background-color: #555;
  cursor: ew-resize;
  position: relative;
  z-index: 1;

  &:hover {
    background-color: teal;
  }

  &:active {
    background-color: teal;
  }
}

.v-card-text {
  position: relative;
}

.file-item:hover .action-icons {
  display: block !important;
}

.action-icons {
  display: none;
}

.download-button {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
